
import { Vue, Component, Ref } from "vue-property-decorator";
import { updateInfo ,searchversion} from "@/api/modules/getmachine";
@Component({
  name: "BasicForm",
})
export default class BasicForm extends Vue {
  @Ref() readonly upload!: any;

  private form = {
    soft_verion: "",
    is_test: "true",
    soft_file: {}
  };
  
  private tableData: Array<any> = [];
    private pageNo = 1;
    private pageSize = 10;
  private fd = new FormData;
  private fileList = [];
  private handleRemove(file: File, fileList: []) {
    this.form.soft_file = {}
  };
  private onSubmit() {
    //this.$refs.uploadImport.submit();
    if(!this.form.soft_verion&&!this.fd){
      this.$message({
          message:'版本号和上传文件不可以为空',
          type: 'warning'
        });
        return
      }
    this.upload.submit();//获取文件的提交动作
    
  };
  private loadJsonFromFile(file: File, fileList: []) {
    this.formd.append('file', file); // 传文件
    console.log(this.formd)
  };
  private async httpRequest(param: any) {
    console.log(param, '000')
    const fileObj = param.file // 相当于input里取得的files
    this.fd.append('soft_file', fileObj)// 文件对象
    this.fd.append('is_test', this.form.is_test)
    this.fd.append('soft_version', this.form.soft_verion)
    let res = await updateInfo(this.fd);
    if(res){
      console.log(res, 'res')
      this.form = {
      soft_verion: "",
      is_test: "true",
      soft_file: {}
    }
        this.$message({
          message: '上传设备成功',
          type: 'success'
        });
      }else{
        this.$message({
          message: '上传设备失败，请联系后台管理员',
          type: 'error'
        });
      }
      this.upload.clearFiles();

  };
  private async mounted() {
    let res = await searchversion(1)
    console.log(res)
    this.tableData = res.list
    this.pageSize = res.total
  };
  private handleSizeChange(val: number) {
      console.log(`每页 ${val} 条`);
      this.pageSize = val;
      this.pageNo = 1;
    }
  
    private handleCurrentChange(val: number) {
      this.pageNo = val;
      console.log(`当前页: ${val}`);
      let str = this.queryparam?"&search="+this.queryparam:""
      let param = val+ str
      console.log(`当前字符串: ${param}`);
      searchversion((param)).then((res)=>{
        this.tableData = res.list;
        this.pageSize = res.total
       })
       
    }
  
}

